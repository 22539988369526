import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import Calendly from "../components/Calendly"
import Formpopup from "../components/FormPopUp/Formpopup";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import logo from "../assets/logo-min.svg";
import { colors } from "./theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import MobileMenu from "./MobileMenu";

const HeaderContainer = styled.header`
  background-color: ${colors.primaryBackground}; /* Use the primary background color from your theme */
  color: ${colors.textWhite}; /* Use the white text color from your theme */
  padding: 1rem 2rem; /* Adjust the padding as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 500;

  @media only screen and (max-width: 40em) {
    padding: 1rem; /* Reduce padding for smaller screens */
  }
`;

const Logo = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.textWhite};
  cursor: pointer;
  img {
    width: 2rem;
    height: auto;
    margin-right: 0.5rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 48em) {
    display: none;
  }
  a {
    font-weight: 600;
    line-height: 1.5;
    color: var(--white);
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 2px;
      background: var(--purple);
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
    }
  }
`;

const Button = styled.button`
  background-color: var(--purple);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const HamburgerBtn = styled.button`
  display: none;

  @media only screen and (max-width: 48em) {
    display: block;
  }

  background-color: transparent;
  border: none;
  cursor: pointer;
  span {
    display: block;
    width: 2rem;
    height: 2px;
    margin: 4px 0;
    background-color: var(--white);
    transition: transform 0.3s ease;
  }

  ${({ clicked }) =>
    clicked &&
    `
    span:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  `}
`;



const Header = () => {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenCalendly, setIsOpenCalendly] = useState(false); // Add this state
  const [isLoading, setIsLoading] = useState();


  const location = useLocation();
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  function goTo(time, id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, time);
  }

  const scrollUp = (id, e) => {
    e.preventDefault();

    if (location.pathname !== "/") {
      navigate("/");
      goTo(300, id);
    } else {
      goTo(100, id);
    }
  };

  const handleMenuClick = (id, e) => {
    setClick(!click);
    scrollUp(id, e);
  };

  useEffect(() => {
    const element = ref.current;
    const mq = window.matchMedia("(max-width: 40em)");

    const animationOptions = {
      position: "fixed",
      top: mq.matches ? 0 : "1rem",
      left: mq.matches ? 0 : "0.5rem",
      right: mq.matches ? 0 : "0.5rem",
      padding: mq.matches ? "1rem 1rem" : "1rem 1rem",
      borderRadius: mq.matches ? "0 0 50px 50px" : "50px",
      border: mq.matches
        ? "2px solid " + colors.textWhite
        : "3px solid " + colors.textWhite,
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: element,
        start: mq.matches ? "bottom+=200 top" : "bottom+=300 top",
        end: mq.matches ? "+=100" : "+=250",
        scrub: true,
      },
    };

    gsap.to(element, animationOptions);

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  // make the responsible header

  useEffect(() => {
    const handleResize = () => {
      setClick(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const openCalendly = () => {
    setIsOpenCalendly(true);
  };

  const closeCalendly = () => {
    setIsOpenCalendly(false);
  };

  return (
    <HeaderContainer ref={ref}>
      <Logo href={location.pathname === "/" ? "/" : "/"}>
        <img src={logo} alt="MithilaStack Logo" />
        <h3>MithilaStack</h3>
      </Logo>
      <Nav>
        <a href="#home" onClick={(e) => scrollUp("home", e)}>
          Home
        </a>
        <a href="#about" onClick={(e) => scrollUp("about", e)}>
          About Us
        </a>
        <a href="#team" onClick={(e) => scrollUp("team", e)}>
          Team
        </a>
        <a href="#services" onClick={(e) => scrollUp("services", e)}>
          Services
        </a>
        <a href="#technology" onClick={(e) => scrollUp("technology", e)}>
          Technology
        </a>
        <a href="#product" onClick={(e) => scrollUp("product", e)}>
          Product
        </a>
        {/* <a href="#portfolio" onClick={(e) => scrollUp("portfolio", e)}>
          Portfolio
        </a> */}
        <Link to="/Careers">Careers</Link>

        {!isLoading && (
        <>
          <Button onClick={openCalendly}>Schedule a Meeting</Button>
        </> )}
       {isPopupOpen && <Formpopup onClose={closePopup} />}
       {isOpenCalendly && <Calendly isOpen={isOpenCalendly} onClose={closeCalendly} />}
        
      </Nav>
      <HamburgerBtn
        aria-label="Open Menu"
        clicked={click}
        onClick={() => setClick(!click)}
      >
        <span></span>
        <span></span>
        <span></span>
      </HamburgerBtn>
      <MobileMenu clicked={click} handleMenuClick={handleMenuClick} />
    </HeaderContainer>
  );
};

export default Header;
