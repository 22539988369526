import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InlineWidget } from "react-calendly";

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 700px; /* Adjust the max-width as needed */
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const CloseButton = styled.button`
  background-color: #3498db; /* Use your primary color */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2073c0; /* Use your hover color */
  }
`;

const Calendly = ({ isOpen, onClose }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    // Add or remove 'overflow-hidden' class on the body when the modal is open
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup the effect
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <>
      {isMounted && isOpen && (
        <ModalOverlay>
          <ModalContainer>
            <ModalContent>
              <InlineWidget url="https://calendly.com/mithilastack/30min" />
              {/* Close button */}
              <div style={{ flex: "1" }} />
              <div style={{ textAlign: "center", marginBottom: "15px" }}>
                <CloseButton
                  type="button"
                  onClick={onClose}
                  aria-label="Schedule Your First Free Advisory Session"
                >
                  Close
                </CloseButton>
              </div>
            </ModalContent>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
};

export default Calendly;