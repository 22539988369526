import React, { useState, useEffect } from "react";
import "./LoadingAnimation.css";
import logo from '../assets/animation.webp';

const LoadingAnimation = () => {
const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulate an asynchronous operation (replace with your actual async code)
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

    return (
    <div>
      <div id="particles-background" className="vertical-centered-box"></div>
      <div className="vertical-centered-box">
        <div className="content">
          <div className="loader-circle">
          <img className="img" src={logo} alt="MithilaStack Logo" />
          </div>
          <div className="loader-line-mask">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;