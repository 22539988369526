import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backgroundImage from "../../assets/popupimg/mithilastack.svg";
import "./formpopup.css";



const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div> 
    </div>
  );
};

function Formpopup({ onClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedService, setSelectedService] = useState("Services");
  const [isPending, setIsPending] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = backgroundImage;
    image.onload = () => {
      // Once the image is loaded, update the state to trigger a re-render
      setImageLoaded(true);
    };
  }, []);

  // Render the component only if the background image is loaded
  if (!imageLoaded) {
    return <Loading />;
  }

  function printError(elementId, message) {
    document.getElementById(elementId).textContent = message;
  }

  function validateName(name) {
    var namePattern = /^[a-zA-Z\s]+$/;
    return namePattern.test(name);
  }

  function validateEmail(email) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  function validateMobile(mobile) {
    var phonePattern = /^(?:\+91)?0*[0-9]{10}$/; // +91 is optional, followed by 10 digits with optional leading zeros
    return phonePattern.test(mobile);
  }

  function updateInputClass(elementId, isValid, value) {
    var element = document.getElementById(elementId);
    if (!value) {
      element.classList.remove("input-2");
      element.classList.remove("input-1");
    } else if (isValid) {
      element.classList.add("input-1");
      element.classList.remove("input-2");
    } else {
      element.classList.add("input-2");
      element.classList.remove("input-1");
    }
  }

  function handleServiceChange(e) {
    setSelectedService(e.target.value);
  }

  function validateForm(e) {
    e.preventDefault();

    var nameErr = !validateName(name);
    var emailErr = !validateEmail(email);
    var mobileErr = !validateMobile(mobile);

    if (nameErr || emailErr || mobileErr) {
      toast.info("Every Field should be filled correctly");
      return; // Prevent form submission when there are errors
    } else {
      setIsPending(true);
      const scriptURL =
        "https://script.google.com/macros/s/AKfycbxVy5FhyXUy6HWj3nsjWOtVxFpDk4b7D3jaXL08BdN04aOmK8L7dbYoHFlA2cC16h11/exec";
      const form = document.forms["contact-form"];
      fetch(scriptURL, {
        method: "POST",
        body: new FormData(form),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.result === "success") {
            console.log(data);
            toast.success("Thank you! Your form is submitted successfully.");
            onClose();
          } else {
            console.log(data);
            toast.error("Error: " + data.error);
            setIsPending(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error: " + error.message);
          setIsPending(false);
        });
    }
  }

  function nameValidate(e) {
    var name = e.target.value;
    setName(name);
    var nameErr = !validateName(name);
    updateInputClass("Name", !nameErr, name);
    if (name == "") {
      printError("nameErr", "");
      return;}
    if (nameErr) {
      printError("nameErr", "Please enter a valid name");
    } else {
      printError("nameErr", "");
    }
  }

  function emailValidate(e) {
    var email = e.target.value;
    setEmail(email);
    var emailErr = !validateEmail(email);

    updateInputClass("Email", !emailErr, email);
    if (email == "") {
      printError("emailErr", "");
      return;}
    if (emailErr) {
      printError("emailErr", "Please enter a valid email");
    } else {
      printError("emailErr", "");
    }
  }

  function mobileValidate(e) {
    var mobile = e.target.value;
    setMobile(mobile);
    var mobileErr = !validateMobile(mobile);

    updateInputClass("Mobile", !mobileErr, mobile);
    if (mobile == "") {
      printError("mobileErr", "");
      return;}
    if (mobileErr) {
      printError("mobileErr", "Please enter a valid Phone Number");
    } else {
      printError("mobileErr", "");
    }
  }
  

  return (
    <>
      <div className={`login-popup`} id="login-popup">
        <div className="box">
          <div className="img-area">
            <div
              className="img"
              style={{ backgroundImage: `url(${backgroundImage})` }}
              alt="Background Image"
            ></div>
          </div>
          <div className="form">
            <div
              className="close"
              onClick={() => {
                onClose();
              }}
            >
              &times;
            </div>
            <h1>Get in Touch With Us</h1>
            <form
              method="post"
              action=""
              name="contact-form"
              id="contact-form"
              onSubmit={validateForm}
            >
              <div className="form-group">
                <input
                  type="text"
                  name="Name"
                  placeholder="Enter Your Name"
                  className="form-control"
                  id="Name"
                  value={name}
                  onChange={nameValidate}
                />
                <span className="error" id="nameErr"></span>
              </div>
              <div className="form-group">
                <input
                  onChange={emailValidate}
                  type="text"
                  name="Email"
                  placeholder="Enter Your Email"
                  className="form-control"
                  id="Email"
                  value={email}
                />
                <span className="error" id="emailErr"></span>
              </div>
              <div className="form-group">
                <input
                  onChange={mobileValidate}
                  type="tel"
                  name="Phone"
                  placeholder="Enter Your Mobile"
                  className="form-control"
                  id="Mobile"
                  value={mobile}
                />
                <span className="error" id="mobileErr"></span>
              </div>
              <div className="form-group">
                <select
                  id="Services"
                  name="Services"
                  className="form-control"
                  onChange={handleServiceChange}
                  value={selectedService}
                >
                  <option value="Services">Services</option>
                  <option value="Full stack development">
                    Full stack development
                  </option>
                  <option value="Mobile application Development">
                    Mobile application Development
                  </option>
                  <option value="Web development">Web development</option>
                  <option value="Administrative application">
                    Administrative application
                  </option>
                  <option value="Digital marketing">Digital marketing</option>
                  <option value="others">Others</option>
                </select>
                {selectedService === "others" && (
                  <input
                    type="text"
                    name="OtherService"
                    placeholder="Enter Other Service"
                    className="form-control"
                  />
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="City"
                  placeholder="Enter Your City"
                  class="form-control"
                  id="City"
                />
              </div>
              <div className="form-group">
                <textarea
                  id="message"
                  name="Message"
                  rows="4"
                  placeholder="How Can We Help You"
                ></textarea>
              </div>
              {isPending ? (
                <Loading />
              ) : (
                <button type="submit" className="btn">
                  Submit
                </button>
              )}
            </form>
            <div id="success-message" style={{ display: "none" }}>
              Message sent successfully!
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Formpopup;