import React from "react";
import "./footer.css";
import MithilastackLogo from "../../assets/iconfooter/logo.svg";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaArrowAltCircleRight,
} from "react-icons/fa";
const Footer = () => {
  return (
    <footer
      className="footer"
      style={{
        backgroundImage: `url(${require("../../assets/iconfooter/footer-bg.png")})`,
      }}
    >
      <div className="footer-top section">
        <div className="container grid-list">
          <div className="footer-brand">
            <a href="#" className="logo">
              {/* This inline style with aspectRatio can help maintain the original aspect ratio while setting the desired width and height. */}
              <img
                src={MithilastackLogo}
                width="20"
                height="10"
                alt="EduCourse logo"
                style={{ aspectRatio: "2/1" }}
              />
            </a>
            <p className="footer-brand-text">
              where innovation meets expertise! We are not just an IT company;
              we are your digital partners on a journey to redefine
              possibilities. Immerse yourself in a world of cutting-edge
              solutions, where each line of code we write is a step towards
              transforming your digital aspirations into reality.
            </p>

            <div className="wrapper">
              <span className="span">Email:</span>
              <a href="mailto:contact@mithilastack.com">
                contact@mithilastack.com
              </a>
            </div>

            <div className="wrapper">
              <span className="span">Call:</span>
              <a href="tel:+918579009245">+91 857 9009 245</a>
            </div>

            <div className="wrapper">
              <span className="span">Address:</span>
              <a
                href="https://maps.app.goo.gl/cq8QFSSk2Pk73Sk19"
                target="_blank"
              >
                Darbhanga, Mithila, Bihar
              </a>
            </div>
          </div>

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Services</p>
            </li>
            <li>
              <a href="#" className="footer-link">
                Mobile App Development
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Cross-Platform App Development
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Full-Stack Development
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Software Testing
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Digital Product Development
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Trending Technology
              </a>
            </li>
          </ul>

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Products</p>
            </li>
            <li>
              <a
                href="https://educourse.mithilastack.com/"
                target="_blank"
                className="footer-link"
              >
                Educourse
              </a>
            </li>
            <li>
              <a
                href="https://eschool.mithilastack.com/"
                target="_blank"
                className="footer-link"
              >
                Edusite
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Healthcare Appointment
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                About
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Contact Us
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Careers
              </a>
            </li>
          </ul>
          <div className="footer-list">
            <p className="footer-list-title">Contacts</p>
            <p className="footer-list-text">
              Enter your email address to register for our newsletter
              subscription
            </p>
            <form action="" className="newsletter-form">
              <input
                type="email"
                name="email_address"
                placeholder="Your email"
                required
                className="input-field"
              />
              <button type="submit" className="btn has-before">
                <span className="span">Subscribe</span>
              </button>
            </form>

            <ul className="social-list">
              <li>
                <a href="https://www.mithilastack.com/" className="social-link">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/mithila-stack/"
                  className="social-link"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a href="https://www.mithilastack.com/" className="social-link">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/MithilaStack"
                  className="social-link"
                >
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCal5EaAQTzvXQk3LXk2FUWw"
                  className="social-link"
                >
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p className="copyright">
            Copyright 2024 All Rights Reserved by{" "}
            <a href="https://mithilastack.com/" className="copyright-link">
              MithilaStack
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
