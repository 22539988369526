
export const colors = {
    colorblack: "#000000", //used in decription and title
    primaryBackground: "#374a59", // Same as header and footer
    textWhite: "var(--white)", // Same as header and footer
    textDarkBlue: "var(--dark-blue)", // Same as header and footer
    textMediumGray: "var(--medium-gray)", // Same as header and footer
    buttonPurple: "var(--purple)", // Same as header and footer
    buttonPrimaryBlue: "var(--primary-blue)", // Same as header and footer
    buttonGreen: "#008000", //used in exporer and get in touch button hover
    lightGrayBackground: "var(--light-gray)", // Same as header and footer
    linkHover: "aqua", // Define your link hover color
    };
  