import React, { useEffect, useState, lazy, Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import { GlobalStyle } from "./globalStyles";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import LoadingAnimation from "./components/LoadingAnimation";

// Lazy-loaded components
const Home = lazy(() => import("./Pages/Home"));
const Careers = lazy(() => import("./Sections/Careers"));
const SoftwareDevelopment = lazy(() => import("./Pages/ServicePages/SoftwareDevelopment"));
const SoftwareUpgradation = lazy(() => import("./Pages/ServicePages/SoftwareUpgradation"));
const DigitalMarketing = lazy(() => import("./Pages/ServicePages/DigitalMarketing"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading with a timeout
  const loadingTimeout = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000); // Adjust the duration as needed
  });

  // Wait for both the loading timeout and lazy-loaded components
  useEffect(() => {
    const loadContent = async () => {
      await Promise.all([
        loadingTimeout,
        import("./Pages/Home"),
        import("./Sections/Careers"),
        import("./Pages/ServicePages/SoftwareDevelopment"),
        import("./Pages/ServicePages/SoftwareUpgradation"),
        import("./Pages/ServicePages/DigitalMarketing"),
      ]);
      setIsLoading(false);
    };

    loadContent();
  }, [loadingTimeout]);

  return (
    <Router>
      <ToastContainer />
      <GlobalStyle />
      <ScrollToTop />
      {isLoading ? <LoadingAnimation /> : <Header />}

      <Suspense fallback={<LoadingAnimation />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Services/SoftwareDevelopment" element={<SoftwareDevelopment />} />
          <Route path="/Services/SoftwareUpgradation" element={<SoftwareUpgradation />} />
          <Route path="/Services/DigitalMarketing" element={<DigitalMarketing />} />
          <Route path="/Careers" element={<Careers />} />
          {/* Add more routes as needed */}
        </Routes>
      </Suspense>

      <Footer />
    </Router>
  );
}

export default App;
